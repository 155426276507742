import { createTheme, useTheme } from '@mui/material/styles';
import { ThemeProvider } from '@emotion/react';
import React from 'react';

export default function ComponentThemeOverrides({ children }: { children: React.ReactNode }) {

  const theme = useTheme();

  const cto = createTheme({
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          body: {
            backgroundColor: theme.palette.background.default,
            "& h1": {
              color: "black"
            }
          }
        }
      },
      MuiAppBar: {
        styleOverrides: {
          root: {
            backgroundColor: theme.palette.primary.main,
          },
        },
      },
      MuiAlert: {
        styleOverrides: {
          root: ({ ownerState }) => ({
            ...(ownerState.severity === 'info' && {
              backgroundColor: '#60a5fa',
            }),
          }),
        },
      },
      MuiCardHeader: {
        styleOverrides: {
          root: {
            backgroundColor: theme.palette.info.main,
            color: theme.palette.info.contrastText,
          },
          title: {
            fontSize: '1.3rem',
          },
          subheader: {
            fontSize: '1rem',
            color: theme.palette.info.contrastText,
          },
        },
      },
      MuiCardActions: {
        styleOverrides: {
          root: {
            backgroundColor: theme.palette.info.light,
            color: theme.palette.info.contrastText,
            justifyContent: 'flex-end',
          },
        },
      },
      MuiBottomNavigation: {
        styleOverrides: {
          root: {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
          },
        }
      },
      MuiBottomNavigationAction: {
        styleOverrides: {
          root: {
            color: theme.palette.primary.contrastText,
            '&.Mui-selected': {
              color: theme.palette.info.contrastText,
              backgroundColor: theme.palette.info.light,
            },
          },
          label: {
            color: theme.palette.primary.contrastText,
            '&.Mui-selected': {
              color: theme.palette.info.contrastText,
              backgroundColor: theme.palette.info.light,
            },
          },
          iconOnly: {
            color: theme.palette.primary.contrastText,
          },
        },
      },
    },
  });

  return (
    <ThemeProvider theme={cto} >
      {children}
    </ThemeProvider>
  );

}