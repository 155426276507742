import { Button, Typography } from "@mui/material";
import Link from "next/link";

/**
 * Default translations object that provides functions for rendering typography components.
 */
import React from "react";

export const defaultTranslations = {
  b: (children: any) => <strong>{children} </strong>,
  button: (children: any) => <Button variant="contained" color="primary">{children}</Button>,
  h4: (children: any) => <Typography variant="h4"> {children} </Typography>,
  h5: (children: any) => <Typography variant="h5"> {children} </Typography>,
  h6: (children: any) => <Typography variant="h6"> {children} </Typography>,
  login: (children: any) => <Link href="/api/auth/login" passHref prefetch={false}>{children}</Link>,
  p: (children: any) => <Typography variant="body1"> {children} </Typography>,
  signup: (children: any) => <Link href="/api/auth/login" passHref prefetch={false}>{children}</Link>,
  ul: (children: any) => <ul>{children}</ul>,
  li: (children: any) => <li>{children}</li>,
  addDog: (children: any) => <Link href="/home/subject/new" passHref prefetch={false}>{children}</Link>,
};

export default defaultTranslations;