'use client';
import * as React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import theme from '@/components/ThemeRegistry/theme';
import ComponentThemeOverrides from '@/components/ThemeRegistry/ComponentThemeOverrides';

export default function ThemeRegistry({ children }: { children: React.ReactNode }) {
  return (
    <ThemeProvider theme={theme}>
      <ComponentThemeOverrides>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline />
        {children}
      </ComponentThemeOverrides>
    </ThemeProvider>
  );
}