/**
 * This is one of the exceptions for the client API
 */
/* eslint-disable no-restricted-imports */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable max-params */
import { getDidTheyApi } from '@didthey/client-api';

export const getApi = (didTheyApiUrl: string | undefined, jwtToken: string | undefined) => {

  return getDidTheyApi({
    config: {
      url: didTheyApiUrl || '',
      requestCredentials: 'include',
      clientName: 'dt-browser',
    },
    populateAuthHeader: async (isAuthenticated: boolean, headers: { [key: string]: string; }) => {
      if (isAuthenticated && jwtToken) {
        headers["Authorization"] = `Bearer ${jwtToken}`;
      }
    },
    uploadAsyncFile:
      async (destUrl: string, fileUri: string, method: string,
        uploadType: string, fieldName: string, headers: { [key: string]: string }): Promise<{
          headers: Record<string, string>;
          status: number;
          mimeType: string | null;
          body: string;
        }> => {
        throw new Error('This should not be called from the client');
      }
  });
}

